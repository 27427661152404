/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: ...
Version: 1.0
*/

.dashboard-container {
    div {
        box-sizing: border-box;
    }

    width: 90%;
    height: calc(100% - 6rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;

    > .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        > .ml-5 {
            margin-left: 5px;
            margin-bottom: 0px;
        }

        > .mr-5 {
            margin-right: 5px;
            margin-bottom: 0px;
        }

        .backDoor
        {
            background-image: url("../assets/images/empty-fridge.jpeg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            position:relative;
            width: 100%;
            height:300px;
            
            margin: 0 auto;
            margin-top:50px;
            position: relative;
        }

        .door
        {
            flex: 1;
            background-color: $theme;
            position:absolute;
            top:0px;
            left:0px;
            width: 100%;
            height:300px;

            transition: all 0.5s ease-in-out;

            &.left {
                transform-origin: left;
                border-right: 1px solid black;

                &.open {
                    transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(-105deg);
                }
            }

            &.right {
                transform-origin: right;
                border-left: 1px solid black;

                &.open {
                    transform: perspective(1200px) translateZ(0px) translateX(0px) translateY(0px) rotateY(105deg);
                }
            }
        }
    }

    .card {
        width: 100%;

        border-radius: 15px;
        background: $base-white;
        padding: 1.5rem;
        margin-bottom: 1.5rem;

        box-shadow: 0 2px 6px $theme-shadow;

        display: flex;
        justify-content: space-between;

        .card-title {
            color: $base-white;
            font-family: sans-serif;
            font-size: 1rem;
        }

        &.open {
            background-color: $green;
            transition: all 0.2s;
        }

        &.close {
            background-color: $red;
            transition: all 0.2s;
        }
    }

    .buttons {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: auto;
        margin-bottom: 3rem;

        > .row {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            margin-bottom: 20px;
            

            > .pushable:first-child {
                margin-right: 5px;
            }

            > .pushable:last-child {
                margin-left: 5px;
            }
        }

        .pushable {
            flex: 1;
            font-family: sans-serif;
            position: relative;
            border: none;
            background: transparent;
            padding: 0;
            cursor: pointer;
            outline-offset: 4px;
            transition: filter 250ms;

            user-select: none;
            -webkit-tap-highlight-color: transparent;

            &:hover {
                filter: brightness(110%);

                .front {
                    transform: translateY(-6px);
                    transition:
                        transform
                        250ms
                        cubic-bezier(.3, .7, .4, 1.5);
                }

                .shadow {
                    transform: translateY(4px);
                    transition:
                        transform
                        250ms
                        cubic-bezier(.3, .7, .4, 1.5);
                }
            }
    
            &:active {
                .shadow {
                    transform: translateY(1px);
                    transition: transform 34ms;
                }

                .front {
                    transform: translateY(-2px);
                    transition: transform 34ms;
                }
            }
    
            &:focus:not(:focus-visible) {
                outline: none;
            }

            .shadow {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 12px;
                background: hsl(0deg, 0%, 5%);
                will-change: transform;
                filter: blur(4px);
                transform: translateY(2px);
                transition:
                    transform
                    600ms
                    cubic-bezier(.3, .7, .4, 1);
            }
    
            .edge {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 12px;
                background: linear-gradient(
                    to left,
                    $theme-shadow 0%,
                    $theme-darker 8%,
                    $theme-darker 92%,
                    $theme-shadow 100%
                );

                &.closed {
                    background: linear-gradient(
                        to left,
                        $red-shadow 0%,
                        $red-darker 8%,
                        $red-darker 92%,
                        $red-shadow 100%
                    );
                }
            }
    
            .front {
                display: block;
                position: relative;
                padding: 12px 42px;
                border-radius: 12px;
                font-size: 1rem;
                color: $base-white;
                background: $theme;
                will-change: transform;
                transform: translateY(-4px);
                transition:
                    transform
                    600ms
                    cubic-bezier(.3, .7, .4, 1);

                &.closed {
                    background: $red;
                }
            }
        }
    }

    .message-json {
        margin: auto;
        color: $base-white;
    }
}