/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: Modern IOT problems require modern IOT solutions
Version: 1.0
*/

@import 'scss/fonts';
@import 'scss/colors';
@import 'scss/login';
@import 'scss/dashboard';

html, body {
    width: 100%;
    height: 100%;
    font-size: 16px;
    background-color: $base-logo;
}

#root {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
}
