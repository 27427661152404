/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: Modern IOT problems require modern IOT solutions
Version: 1.0
*/

.login-container {
    width: 75vw;
    border-radius: 10px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
        width: 50%;
        object-fit: contain;
    }
    
    .header {
        font-family: MavenProMedium, sans-serif;
        font-size: 1.5rem;
        color: $base-white;
        margin-bottom: 3rem;
    }

    .label {
        font-family: Quicksand, sans-serif;
        font-size: 1rem;
    }

    .input-container {
        position: relative;
        overflow: hidden;
        display: flex;
        padding-bottom: 0.5rem;
        width: 100%;
        height: 2rem;
        margin-bottom: 1rem;

        .label, .label-sm {
            position: absolute;
            top: 50%;
            color: #7b7684;
            pointer-events: none;
            transition: all 0.35s;
        }

        .label {
            left: 0;
            transform: translate(0, -50%);
        }

        .label-sm {
            right: 0;
            transform: translate(3rem, -50%);
            width: 6rem;
            font-size: 0.75rem;
            text-align: right;
            opacity: 0;
            transition: all 0.35s;
        }

        input {
            margin-bottom: 2px;
            width: inherit;
            height: inherit;
            border: none;
            border-bottom: 2px solid $base-white;
            outline: none;
            background: transparent;
            color: $base-white;
            font-size: 1rem;
            transition: all 0.35s;

            &:focus {
                border-bottom: 2px solid $theme;
                border-bottom-color: $theme;
            }

            &:focus ~ .label,
            &:not(:placeholder-shown) ~ .label {
                opacity: 0;
                transform: translate(-3rem, -50%);
            }

            &:focus ~ .label-sm,
            &:not(:placeholder-shown) ~ .label-sm {
                opacity: 1;
                transform: translate(0, -50%);
            }
        }   
    }

    .buttons {
        width: 100%;
        display: flex;
        margin-top: 3rem;
        justify-content: space-between;

        .btn-signup, .btn-login {
            all: unset;
            font-size: 0.9rem;
            color: $base-white;
        }
        
        .btn-login {
            width: 20vw;
            padding: 10px 20px;
            background-color: $theme;
            border-radius: 8px;
            font-size: 0.9rem;
            text-align: center;
            transition: background-color 0.35s;

            &:active {
                background-color: #303f4f;
                transition: background-color 0.35s;
            }
        }
    }
}