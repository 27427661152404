/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: ...
Version: 1.0
*/

$base-logo: #242D39;
$base-white: #e0e5eb;

$theme: hsl(214deg, 23%, 40%);
$theme-darker: hsl(211deg, 24%, 25%);
$theme-shadow: hsl(211deg, 24%, 10%);

$red: hsl(340deg, 100%, 47%);
$red-darker: hsl(340deg, 100%, 32%);
$red-shadow: hsl(340deg, 100%, 16%);

$green: hsl(136, 54%, 43%);