/*
Author: KurisuCodes
Author URI: https://github.com/kurisukodes
Description: ...
Version: 1.0
*/

@font-face {
    font-family: 'MavenPro';
    src: local('MavenPro'), url(../fonts/MavenPro-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'MavenProMedium';
    src: local('MavenProMedium'), url(../fonts/MavenPro-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'MavenProSemiBold';
    src: local('MavenProSemiBold'), url(../fonts/MavenPro-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'MavenProBold';
    src: local('MavenProBold'), url(../fonts/MavenPro-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Quicksand';
    src: local('Quicksand'), url(../fonts/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'QuicksandLight';
    src: local('QuicksandLight'), url(../fonts/Quicksand-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'QuicksandMedium';
    src: local('QuicksandMedium'), url(../fonts/Quicksand-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'QuicksandSemiBold';
    src: local('QuicksandSemiBold'), url(../fonts/Quicksand-SemiBold.ttf) format('truetype');
}
@font-face {
    font-family: 'QuicksandBold';
    src: local('QuicksandBold'), url(../fonts/Quicksand-Bold.ttf) format('truetype');
}